<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div id="exampleModal" class="modal fade" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 id="exampleModalLabel" class="modal-title">KATEGORI PRODUK</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                v-model="search"
                class="pt-5"
                label="Search"
                dense
                outlined
                @keyup="doSearch()"
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA KATEGORI PRODUK</th>
                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">
                        {{ data.nama_kategoriproduk | capitalize }}
                      </td>

                      <td class="text-center">
                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          class="me-3 mt-4"
                          @click="PilihKtegoriProduk(data.id_kategoriproduk, data.nama_kategoriproduk)"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="page--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="defautlPhoto()"></v-img>
        </v-avatar>

        <!-- upload photo -->
        <!-- <div> -->
        <v-col cols="10">
          <div style="padding-top: 25px">
            <v-alert type="error">
              Pastikan anda memasukkan produk barang dengan benar di toko <strong> {{ showtokos.nama_toko }}</strong
              >.
            </v-alert>
          </div>
        </v-col>
        <!-- <v-btn color="primary" class="me-3 mt-5" @click="$refs.refInputEl.click()">
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">Upload new photo</span>
          </v-btn>

          <input ref="refInputEl" type="file" accept=".jpeg,.png,.jpg,GIF" :hidden="true" />

          <v-btn color="error" outlined class="mt-5"> Reset </v-btn>
          <p class="text-sm mt-5">Allowed JPG, GIF or PNG. Max size of 800K</p> -->
        <!-- </div> -->
      </v-card-text>

      <v-card-text>
        <!-- <v-form class="multi-col-validation mt-6"> -->
           <form ref="form"  enctype="multipart/form-data" @submit.prevent="CreateData">
          <v-row>
            <v-col md="6" cols="12">
              <v-text-field
                id="nama_kategoriproduk"
                v-model="nama_kategoriproduk"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Kategori Produk"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                id="id_kategoriproduk"
                v-model="produk.id_kategoriproduk"
                class="required"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                dense
                outlined
                readonly

              ></text>
            </v-col>

            <v-col md="12" cols="12">
              <v-textarea  v-model="produk.keterangan" clearable clear-icon="mdi-close-circle" label="Keterangan"></v-textarea>
            </v-col>

            <v-col cols="12" md="12">
              <div style="float: left"><strong>DETAIL PRODUK</strong> &nbsp;</div>

              <hr />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field type="number"  :rules="[rulesstok.numberRule]"  dense label="Stok" v-model="produkbarang.stok" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field type="number"   :rules="[rulesberat.numberRule]" dense label="Berat"  v-model="produkbarang.berat"  outlined></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <!-- <v-text-field v-model="produkbarang.harga" dense outlined label="Harga"></v-text-field> -->
                <currency-input dense outlined v-model="produkbarang.harga" label="Price (Rp)" currency="IDR" locale="id" class="form-control"/></currency-input>
            </v-col>

            <v-col md="12" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Gambar Produk </v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <img :src="showPhotoProduk()" />
                </div>
              </div>

              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <span
                  v-for="(gambar, index) in gambarprodukbarang.photo_produkbarang"
                  class="cropped-image product-image-thumbs"
                  style="cursor: pointer; padding: 4px"
                >
                  <img :src="gambar" @click="deleteGambarProduk(index)" />
                </span>
              </section>
              <div>
                <span v-if="gambarprodukbarang.photo_produkbarang.length != 0"
                  ><v-chip class="ma-2" color="error" text-color="white"> *Klik gambar untuk menghapus! </v-chip></span
                >
              </div>
              <v-btn type="submit" color="success" class="me-3 mt-4" @click.prevent="showFileChooser">
                Choose Image
              </v-btn>
              <!-- <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImage"> Crop </v-btn> -->

              <input
                ref="input"
                style="display: none"
                id="photo_gallery"
                type="file"
                accept="image/*"
                @change="uploadPhotoGallerys"
              />
            </v-col>
            <v-col md="6" cols="12">
              <v-header>Tersedia</v-header>
              <v-radio-group v-model="produk.tersedia" row>
                <v-radio label="Yes" value="Y" color="info"></v-radio>
                <v-radio label="No" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <!-- alert -->
            <!-- <v-col cols="12">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      Your email is not confirmed. Please check your inbox.
                    </p>
                    <a href="javascript:void(0)" class="text-decoration-none warning--text">
                      <span class="text-sm">Resend Confirmation</span>
                    </a>
                  </div>
                </div>
              </v-alert>
            </v-col> -->

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'tokos-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>

            <!-- <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save  </v-btn>
              <v-btn color="secondary" outlined class="mt-4" type="reset" @click.prevent="resetForm"> Cancel </v-btn>
            </v-col> -->
          </v-row>
          </form>
        <!-- </v-form> -->


      </v-card-text>
    </v-card><br/>
     <v-card>
        <v-card-text>
          <v-card-title class="text-no-wrap pt-1 ps-2"> PRODUK</v-card-title>

          <v-text-field class="pt-5" label="Search" @keyup="doSearchProduks()" v-model="search" dense outlined></v-text-field>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase">NO</th>
                  <th class="text-center text-uppercase">KETERANGAN</th>
                  <th class="text-center text-uppercase">STOK</th>
                  <th class="text-center text-uppercase">BERAT(KG)</th>
                  <th class="text-center text-uppercase" width="200px">HARGA(Rp)</th>
                  <th class="text-center text-uppercase">JUMLAH TERJUAL</th>
                  <th class="text-center text-uppercase">GAMBAR</th>
                  <th class="text-center text-uppercase">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in displayDataProduks()" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td class="text-center">{{ data.keterangan | capitalize  }}</td>
                  <td class="text-center">
                    {{ data.stok  }}
                  </td>

                  <td class="text-center">
                    {{ data.berat }}
                  </td>
                  <td class="text-center">
                    {{ data.harga | currency}}
                  </td>

                   <td class="text-center">
                    {{ data.jumlah_terjual}}
                  </td>

                  <td class="text-center">
                    <!-- {{ gambarproduks}} -->
                    <v-row>
                     <v-col v-for="(dg, index) in gambarproduks[data.id_produkbarang]" :key="index"   class="d-flex child-flex"
                        cols="4">
                          <v-img
                          width="200px"
                          :src="showGambarProduk() + '/images/produk/barang/' + dg.id_produkbarang + '/' + dg.photo_produkbarang"
                          :lazy-src="showGambarProduk() + '/images/produk/barang/' + dg.id_produkbarang + '/' + dg.photo_produkbarang"
                          aspect-ratio="1"
                          class="grey lighten-2"
                          />
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                          </v-img>

                      </v-col>
                   </v-row>
                  </td>

                  <td class="text-center">
                      <v-btn

                      :to="{
                        name: 'tokos-produk-barang-edit',
                        params: { id_produk: data.id_produk, id_produkbarang: data.id_produkbarang},
                      }"
                      color="warning"
                      class="me-3 mt-4"
                    >
                      <v-icon color="#000000">
                        {{ icons.mdiGreasePencil }}
                      </v-icon>
                    </v-btn>
                  </td>

                  <!-- <td class="text-center">
                    <v-btn
                      v-if="data.id_jenisusaha == 'd001e490-e5e7-4a86-9359-0c5e8fb17ee6'"
                      :to="{
                        name: 'tokos-produk-barang',
                        params: { id_toko: data.id_toko, id_jenisusaha: data.id_jenisusaha },
                      }"
                      color="warning"
                      class="me-3 mt-4"
                    >
                      <v-icon color="#000000">
                        {{ icons.mdiStoreEdit }}
                      </v-icon>
                    </v-btn>

                    <v-btn
                      v-if="data.id_jenisusaha == '85ed9447-e397-4e9c-a254-55698bb0620f'"
                      :to="{
                        name: 'tokos-produk-jasa',
                        params: { id_toko: data.id_toko, id_jenisusaha: data.id_jenisusaha },
                      }"
                      color="warning"
                      class="me-3 mt-4"
                    >
                      <v-icon color="#000000">
                        {{ icons.mdiNaturePeople }}
                      </v-icon>
                    </v-btn>

                    <v-btn @click="deletePost(data.id_toko, index)" color="error" class="mt-4" type="button">
                      <v-icon color="#ECEFF1">
                        {{ icons.mdiDelete }}
                      </v-icon>
                    </v-btn>
                  </td> -->
                </tr>
              </tbody>
               <tfoot>
                    <tr>
                      <td colspan="8">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <div class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageProduks == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                                @click="pageProduks--"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                            <div
                              v-for="pageNumber in pagesProduks.slice(
                                pageProduks - 1,
                                pageProduks + 5,
                              )"
                              :key="pageNumber"
                              :class="['v-data-footer', pageNumber == pageProduks ? 'active' : '']"
                              @click="pageProduks = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </div>
                            <div class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  pageProduks < pagesProduks.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="pageProduks++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </div>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import {
  mdiCheckBold,
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
  mdiStoreEdit,
} from '@mdi/js'

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Visa') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Members') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }
    return {
      resolveStatisticsIconVariation,

      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Toko',
          disabled: false,
          href: '/admin/tokos',
        },
        {
          text: 'Produk',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiCheckBold,
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
        mdiStoreEdit,
      },

      produk: {
        id_kategoriproduk: '',
        id_toko: '',
        keterangan: '',
        tersedia: 'Y',
      },
      produkbarang: {
        stok: 0,
        berat: 0,
        harga: '',
      },
      gambarprodukbarang: {
        photo_produkbarang: [],
      },

      nama_kategoriproduk: '',
      dataproduks: [],
      gambarproduks: [],

      rulesstok: {
        numberRule: val => {
          if (val < 0) return 'Please enter a positive number'
          return true
        },
      },

      rulesberat: {
        numberRule: val => {
          if (val < 0) return 'Please enter a positive number'
          return true
        },
      },

      kategoriproduk: [],
      showtokos: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],

      pageProduks: 1,
      perPageProduks: 10,
      pagesProduks: [],
      searchProduks: '',
      searchResultProduks: [],
    }
  },

  mounted() {
    this.getAllData()
    this.$isLoading(true)
    this.showGambarProduk()
    this.getAllDataProduk()
    this.GetToko()
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  filters: {
    capitalize: function (data) {
      var capitalized = []
      data.split(' ').forEach(word => {
        capitalized.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      })
      return capitalized.join(' ')
    },
  },
  methods: {
    showGambarProduk() {
      const imageProduks = `${process.env.VUE_APP_ROOT_API}`
      return imageProduks
    },

    getAllDataProduk() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + `/api/admin/produks/${this.$route.params.id_toko}`
      return this.axios.get(url).then(response => {
        this.dataproduks = response.data.dataproduk
        this.gambarproduks = response.data.gambarproduk
        // console.log('NAMA ROLE: ' + response.data.users.role_rel.nama_role)
      })
    },

    CreateData(e) {
      // console.log('PHOTO: ' + this.produk.id_kategoriproduk)
      if (this.$data.produk.id_kategoriproduk.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Kategori produk required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.produk.keterangan.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Keterangan required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.produkbarang.stok.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Stok required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.produkbarang.berat.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Berat required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else if (this.$data.produkbarang.harga.length === 0) {
        this.$swal.fire({
          title: 'Error',
          text: 'Harga required !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        const uri = `${process.env.VUE_APP_ROOT_API}/api/admin/produks/store`

        this.axios
          .post(uri, {
            id_toko: this.$route.params.id_toko,
            id_jenisusaha: this.$route.params.id_jenisusaha,
            id_kategoriproduk: this.produk.id_kategoriproduk,
            keterangan: this.produk.keterangan,
            tersedia: this.produk.tersedia,
            stok: this.produkbarang.stok,
            berat: this.produkbarang.berat,
            harga: this.produkbarang.harga,
            photo_produkbarang: this.gambarprodukbarang.photo_produkbarang,
          })
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Produk created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            setTimeout(() => {
              // this.$router.push({ name: 'produk-index' })
              window.location.reload()
            }, 3000)
          })

        e.preventDefault()
      }
    },

    uploadPhotoGallerys(e) {
      if (e.target.files[0].size > 2000000) {
        this.$swal.fire({
          title: 'Warning!',
          text: 'File size too large! File size must be smaller than 2MB!',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
        })
        return false
      }

      let readerPhoto = new FileReader()
      readerPhoto.readAsDataURL(e.target.files[0])
      readerPhoto.onload = res => {
        this.gambarprodukbarang.photo_produkbarang.push(res.target.result)
      }
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.gambarprodukbarang.photo_produkbarang = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.gambarprodukbarang.photo_produkbarang = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    deleteGambarProduk(index) {
      // splice(start, length)
      this.gambarprodukbarang.photo_produkbarang.splice(index, 1)
    },

    showPhotoProduk() {
      // console.log('PRODUK GAMBAR :' + this.gambarprodukbarang.photo_produkbarang.length)
      if (this.gambarprodukbarang.photo_produkbarang.length == 0) {
        const imageDefaultProduk = `${process.env.VUE_APP_ROOT_API}/images/produk/default_img.png`

        return imageDefaultProduk
      }
      // const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.gambarprodukbarang.photo_produkbarang}`

      // return imageClient
    },

    defautlPhotoProduk() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/produk/default_img.png`
      return imageDefault
    },

    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/icon/tokobarang.png`
      return imageDefault
    },

    getAllData() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/admin/kategoriproduks'
      return this.axios.get(url).then(response => {
        this.kategoriproduk = response.data.kategoriproduks
      })
    },

    GetToko() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uri =
        process.env.VUE_APP_ROOT_API +
        `/api/admin/tokos/${this.$route.params.id_toko}/${this.$route.params.id_jenisusaha}/show`
      this.axios.get(uri).then(response => {
        this.showtokos = response.data.showtoko
        // console.log(this.role.nama_role)
      })
    },

    // Paging Produk
    doSearchProduks() {
      const go = this.dataproduks.filter(item =>
        this.searchProduks
          .toLowerCase()
          .split(' ')
          .every(v => item.keterangan.toLowerCase().includes(v)),
      )
      this.searchResultProduks = go
      this.pagesProduks = []
      this.setPagesDataProduks()
    },
    displayDataProduks() {
      let paginateProduks

      if (this.searchProduks == '') {
        paginateProduks = this.paginateProduks(this.dataproduks)
      } else {
        paginateProduks = this.paginateProduks(this.searchResultProduks)
      }

      return paginateProduks
    },
    setPagesDataProduks() {
      let numberOfPages

      if (this.searchProduks == '') {
        numberOfPages = Math.ceil(this.dataproduks.length / this.perPageProduks)
      } else {
        numberOfPages = Math.ceil(this.searchResultProduks.length / this.perPageProduks)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pagesProduks.push(index)
      }
    },
    paginateProduks(dataproduks) {
      const { pageProduks } = this
      const { perPageProduks } = this
      const from = pageProduks * perPageProduks - perPageProduks
      const to = pageProduks * perPageProduks

      return dataproduks.slice(from, to)
    },
    // End Paging Produk

    doSearch() {
      const go = this.kategoriproduk.filter(item =>
        this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_kategoriproduk.toLowerCase().includes(v)),
      )
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.kategoriproduk)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.kategoriproduk.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(kategoriproduk) {
      const { page } = this
      const { perPage } = this
      const from = page * perPage - perPage
      const to = page * perPage

      return kategoriproduk.slice(from, to)
    },
    PilihKtegoriProduk(id_kategoriproduk, nama_kategoriproduk) {
      document.querySelector('#id_kategoriproduk').setAttribute('value', id_kategoriproduk)
      document.querySelector('#nama_kategoriproduk').setAttribute('value', nama_kategoriproduk)
      this.produk.id_kategoriproduk = id_kategoriproduk
      this.nama_kategoriproduk = nama_kategoriproduk
    },
  },
  watch: {
    kategoriproduk() {
      this.pages = []
      this.setPages()
    },

    dataproduks() {
      this.pagesProduks = []
      this.setPagesDataProduks()
    },
  },
}
</script>
